@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Inter Tight';
  src: url('../src/assets/fonts/InterTight-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Tight';
  src: url('../src/assets/fonts/InterTight-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Tight';
  src: url('../src/assets/fonts/InterTight-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Archivo Black';
  src: url('../src/assets/fonts/ArchivoBlack-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Tight';
  src: url('../src/assets/fonts/InterTight-Medium.ttf') format('truetype'); /* Replace with the correct file name and path */
  font-weight: 600;
  font-style: normal;
}
html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
img {
    max-width: 100%; /* Ensure images do not exceed the width of their parent container */
}


body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter Tight', sans-serif;
}
.react-tabs{
    width: 100%;
}
.react-tabs__tab-list {
    border:none !important;
}

.react-tabs__tab--selected {
    background-color: #112d56 !important;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 500;
}
select {
    width: 220px;
    background-color: #F2F0ED;
    color: #112D56;
    padding: 8px 2px;
    height: '36px';
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Inter Tight', sans-serif;

}
select > option {
    background-color: #FFFFFF; /* Set the background color of the options */
    color: #112D56; /* Set the text color of the options */
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-top: 20px;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    display: block;
    padding: 4px 10px;
    text-decoration: none;
    background-color: #F2F0ED;
    color: black;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .page-link:hover {
    background-color: #112D56;
    color: white;
  }
  
  .active .page-link {
    background-color: #112D56;
    color: white;
    border-color: #112D56;
  }
  
  .disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: rgba(242, 240, 237, 0.5); /* #F2F0ED with some opacity */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* For Firefox */
  .hide-scrollbar {
    scrollbar-width: none;
  }
  
  /* Optional: Prevent scrollbars while still enabling scroll behavior */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE 10+ */
    overflow: -moz-scrollbars-none; /* Older Firefox */
    overflow: hidden;
    overflow-y: auto; /* Enable vertical scrolling without a visible scrollbar */
    overflow-x: auto; /* Enable horizontal scrolling without a visible scrollbar */
  }